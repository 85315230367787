@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #393842 !important;
  color: rgb(235, 238, 241) !important;
  overflow: hidden;
}

.center{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn{
  width: 90%;
  height: 20vh;
  margin-top: 3%;
}

section{
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color:aliceblue !important;
}

.topic {
  text-align: left;
}

.container {
  display: flex;
  row-gap: 20px;
  flex-wrap: wrap;
}

.box {
  width: 30%;
  margin-left: 3%;
}

.row{
  margin-bottom: 300px;
}

.split-para { 
  display:flex;
  flex-wrap: wrap;
  margin:10px;
}

.blocktext{
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
}

.blocktextsmall{
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
  font-size: large;
}

.shortblock {
  margin-top: -2%;
  /* width: 50% !important;
  height: 0% !important; */
}

.left{
  margin-left: 25vw;
  float:left;
}

.right{
  margin-right: 25vw;
  float:right;
}

.center{
  float:none;
}

.loud{
  font-size: large;
  font-weight: 600;
}

.contact{
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: x-large;
  padding-bottom: 1%;
  border-bottom: 2px solid #000000;
}

.carBox{
  width: 50% !important; 
  margin: auto !important;
  margin-top: -1% !important;
}

.font1{
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font2{
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font3{
  font-family: "Jacquarda Bastarda 9", serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font4{
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font5{
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font6{
  font-family: "Rubik Glitch Pop", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font7{
  font-family: "Workbench", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "BLED" 0,
    "SCAN" 0;
  font-size: xx-large;
}

.font8{
  font-family: "Londrina Solid", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: xx-large;
}

.font9{
  font-family: "Bungee", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font10{
  font-family: "Rubik Bubbles", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font11{
  font-family: "Unica One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font12{
  font-family: "Rubik Doodle Shadow", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

.font13{
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
}

@media only screen and (max-width: 600px) {
  .box {
    width: 90%;
  }

  .contact{
    justify-content: center;
    flex-direction: column;
    font-size: large;
    padding-bottom: 5%;
    border-bottom: 2px solid #000000;
  }

  .split-para { 
    display:flex;
    flex-direction: column;
    margin: 3%
  }

  .shortblock {
    margin-top: 2%;
  }
}